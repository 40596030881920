import React from 'react'
import ApiCollapse from '../components/apiCollapse'
import { Alert } from 'antd'
import Layout from '../components/layout'
import SEO from '../components/seo'


class ApiIntroduction extends React.Component{
    render(){
        return (
            <Layout>
                 <SEO title="API" description="Read our GraphQL API documentation and learn about how you
                 could integrate Emaildrop to your app or simply use it for you're personal use"/>
                <main>
                    <div className="main-wrapper">
                        <h1 className="title">GraphQL API - Introduction</h1>
                        <div className="api">
                            <ApiCollapse />
                            <div className="contents">
                                <div className="content" id="introduction-to-graphql">
                                    <h2>Introduction to GraphQL</h2>
                                    <div className="paragraph">
                                        <p>
                                            GraphQL is a query language for APIs. It differs from conventional
                                            APIs in the sense that it gives clients the power to ask for exactly 
                                            what they need and nothing more. Emaildrop chose GraphQL because it not only
                                            offers our clients the capibility to perform a number of varied task from a 
                                            single endpoint but complete flexibity with regards to what they want 
                                            returned from their request. You can learn more about GraphQL at 
                                            <a href="https://graphql.org/"> https://graphql.org/</a>.
                                        </p> 
                                    </div>
                                </div>
                                <div id="making-requests">
                                    <h2>Making requests</h2>
                                    <div className="paragraph">
                                        <p>
                                            The easiest way to make requests to Emaildrop's GraphQL server is
                                            to head over to our GraphQL explorer which can be found at 
                                            <a href="https://api.emaildrop.io/graphql"> https://api.emaildrop.io/graphql</a>
                                            . However, eventually you'll want to make request programatically. You can do this
                                            using client libraries. We recommend using <a href="https://www.apollographql.com/docs/react/">Apollo Client</a> if you're 
                                            working with Javascript. A list of client libraries for different languages can be
                                            found on the official GraphQL website.
                                        </p>
                                        <Alert
                                        message="Note"
                                        description="All of Emaildrop's GraphQL queries, mutations
                                        and subsriptions are public, so authentication is not required. As a result, you can 
                                        get started immediately using our API to recieve, delete, subscribe, and view emails."
                                        type="info"
                                        showIcon
                                        />
                                    </div>
                                </div>
                                <div id="rest-api-support">
                                    <h2>REST API support</h2>
                                    <div className="paragraph">
                                        <p> 
                                            Emaildrop has no plans to support a REST API
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </main>
            </Layout>
        )
        
    }
}

export default ApiIntroduction